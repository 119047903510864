import Cookies from "universal-cookie";

const cookies = new Cookies();
const COUNTRIES_COOKIE = "countries";
const CREDIT_OFFER_RENEW_COOKIE = "credit_offer_renew";
const COUNTRY_COOKIE = "country";
const CONFIG_COOKIE = "config";

const setCookie = (name, value, max_age = 86400) => {
  cookies.set(name, value, { maxAge: max_age, path: "/" });
};

export const setCountriesCookie = (countries) => {
  setCookie(COUNTRIES_COOKIE, countries);
};

export const getCountiresCookie = () => {
  return cookies.get(COUNTRIES_COOKIE);
};

export const setConfigCookie = (countries) => {
  setCookie(CONFIG_COOKIE, countries);
};

export const getConfigCookie = () => {
  return cookies.get(CONFIG_COOKIE);
};

export const setCreditOfferRenewCookie = () => {
  return setCookie(CREDIT_OFFER_RENEW_COOKIE, true, 604800);
};

export const getCreditOfferRenewCookie = () => {
  return cookies.get(CREDIT_OFFER_RENEW_COOKIE);
};

export const setCountryCookie = (country) => {
  setCookie(COUNTRY_COOKIE, country);
};

export const getCountryCookie = () => {
  return cookies.get(COUNTRY_COOKIE);
};
