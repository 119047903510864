import { Item } from "./item";

const ActiveLoans = ({ loans, me, error, loanType }) => {
  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  if (loans.length === 0) {
    return (
      <p className="flex flex-col p-2 text-float-grey-1">
        You don't have any {loanType} loans.
      </p>
    );
  }
  return (
    <>
      <div className="w-full h-full overflow-auto border-b">
        <table className="w-full mb-4">
          <tr className="text-float-grey-1 sticky top-0 bg-white">
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Loan number
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Amount
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Activation date
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Interest
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Repaid
            </th>
          </tr>
          {loans.map((i, index) => (
            <Item
              key={index}
              index={index}
              country={me.selected_company.country || "en"}
              {...i}
            />
          ))}
        </table>
      </div>
    </>
  );
};

export default ActiveLoans;
