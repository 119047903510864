import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import { downloadInvoice } from "../../api";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";
import fileDownload from "js-file-download";
import { formatDate } from "../../common/utils";

const InvoiceItem = ({ invoice, country }) => {
  let formatter = new Intl.NumberFormat(country);
  const amount = `${formatter.format(invoice.total)} ${invoice.currency_code}`;
  const paid_amount = `${formatter.format(invoice.paid_amount)} ${
    invoice.currency_code
  }`;
  const color =
    invoice.status === "overdue" ? "text-float-red-1" : "text-float-black-1";

  const downloadInvoicePDF = async (loan_reference, invoice_id) => {
    const file = await downloadInvoice(loan_reference, invoice_id);
    fileDownload(file, `${invoice_id}.pdf`);
  };

  return (
    <tr className="text-black border-b-gray-100 border-b-2">
      <td className="px-2">
        <p className={`${color} text-sm md:text-base`}>{amount}</p>
      </td>
      <td className="px-2">
        <p className={`${color} text-sm md:text-base`}>
          {formatDate(invoice.due_date)}
        </p>
      </td>
      <td className="px-2">
        <p className={`${color} text-sm md:text-base`}>{paid_amount}</p>
      </td>
      <td className="px-2 text-sm md:text-base">
        {invoice.pdf_available ? (
          <p
            className="cursor-pointer text-float-purple-2"
            onClick={() => {
              downloadInvoicePDF(invoice.loan_reference, invoice.invoice_id);
            }}
          >
            See Invoice
          </p>
        ) : (
          <p className="italic">Not available yet</p>
        )}
      </td>
    </tr>
  );
};

const Invoice = () => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: "v1/invoice/",
  });
  if (loading || user_loading) {
    return <Loading />;
  }

  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  if (result.length === 0) {
    return (
      <p className="flex flex-col p-2 text-float-grey-1">
        You don't have any upcoming payments.
      </p>
    );
  }
  result.sort(function (a, b) {
    return new Date(b.due_date) - new Date(a.due_date);
  });
  return (
    <div className="w-full overflow-auto h-full">
      <table className="w-full">
        <tr className="text-float-grey-1 sticky top-0 bg-white">
          <th className="font-bold p-2 text-left text-sm md:text-base">
            Amount
          </th>
          <th className="font-bold p-2 text-left text-sm md:text-base">
            Due date
          </th>
          <th className="font-bold p-2 text-left text-sm md:text-base">Paid</th>
          <th className="font-bold p-2 text-left text-sm md:text-base">
            Invoice
          </th>
        </tr>
        {result.map((invoice, index) => (
          <InvoiceItem
            key={index}
            index={index}
            invoice={invoice}
            country={me.selected_company.country || "en"}
          />
        ))}
      </table>
    </div>
  );
};

export default Invoice;
